define(
  ({
    copy: 'Kopyala',
    paste: 'Yapıştır',
    copyToAll: 'Tümüne Kopyala',
    add_to_cart: 'Sepete Ekle',
    addToCart: 'Sepete Ekle',
    remove: 'Kaldır',
    added: 'Eklendi',
    dropped: 'Bırakıldı',
    units: 'Birimler',
    total: 'Toplam',
    subtotal: 'Alt toplam',
    moveToShipment: 'Gönderime Taşı',
    chooseOption: 'Bir Seçenek Belirle',
    availability_msg: 'Talep edilen bazı miktarlar gönderme tarihinde mevcut değil',
    atsWarning: 'Bu miktar %{availableOn} tarihine kadar müsait olmayacak.',
    inventory_warehouse: 'Envanter Deposu',
    notInCatalog: 'Öge Sepete Eklenemedi',
    notInCatalogExplanation: 'Üzgünüz, ama Kataloglar arasında öğe ekleme yapılamaz.',
    digital_market_notInCatalogExplanation: 'Sepetin aynı anda yalnızca bir markanın ürünlerini içerebilir',
    removeFromCartSuggestion: '<link>Sepetini boşaltmak</link> ister misin?',
    digital_market_removeFromCartSuggestion: 'Bu öğeyi eklemek için <link>sepetini boşaltabilirsin</link>',
    emptyCart: 'Sepeti Boşalt',
    empty: 'Boş',
    emptyConfirmation: 'Sepetini boşaltmak istediğinden emin misin?',
    available_on: 'Şunda Müsait:',
    personalization: 'Kişiseleştirme',
    personalize: 'Kişiselleştir',
    configuration: 'Yapılandırma',
    configure: 'Özelleştir',
    placement: 'Yerleştirme',
    content: 'İçerik',
    color: 'Renk',
    product_specification: 'Ürün Özellikleri',
    type: 'Tür',
    logo: 'Logo',
    text: 'Metin',
    text_and_logo: 'Metin, Logo',
    other: 'Diğer',
    value_might_be_stale: 'Sepetindeki değişikliklerden dolayı bu değerin tarihi geçmiş olabilir. Tekrar hesaplamak için siparişini kaydet.',
    more_details: 'Daha Fazla Ayrıntı',
    availability: 'Müsaitlik Durumu',
    no_scheduled_availability: 'Planlanan Müsait Olma Yok',
    not_available_until: '<date /> tarihine kadar müsait değil',
    available_as_of: '<date /> tarihi itibarı ile müsait',
    units_bundled_singular_label: '%{units} Birim Paketlendi',
    units_bundled_label: '%{units} Birim Paketlendi',
    add_bundle_to_cart: 'Sepete Paket Ekle',
    bundles: 'Paketler',
    bundled: 'Paketlendi',
    product: 'Ürün',
    sizes_and_quantities: 'Boyut / Miktar',
    units_singular_label: '%{units} Birim',
    units_label: '%{units} Birim',
    current_availability: 'Mevcut Müsaitlik Durumu',
    future_availability: 'Gelecek Müsaitlik Durumu',
    size: 'Boyut',
    sku: 'SKU',
    availability_date: 'Müsaitlik Tarihi',
    quantity: 'Mkt.',
    availability_subject_to_change: 'Müsaitlik durumu değişebilir',
    future_availability_subject_to_change: '*Kullanılabilirlik değişebilir ve sözleşme envanterini içerir.',
    contract_inventory_availability: 'Sözleşme Envanteri Kullanılabilirliği**',
    contract_inventory_footer: '**Sözleşme envanterinin kullanılabilirliği, sipariş gönderildikten sonra güncellenecektir.',
    yes: 'Evet',
    no: 'Hayır',
    copy_to_all_confirmation_message: 'Bu beden aralığını aşağıdaki tüm tarzlara kopyalamak istediğinden emin misin?',
    no_configurator_data: 'Yapılandırıcı verisi yok',
    oops: 'Hata!',
    something_went_wrong: 'Bir şeyler ters gitti!',
    error_message: 'Endişelenmeyin, bu hatayı bildirmek için sizin için destek ekibiyle iletişime geçtik!',
    customize_item: 'Öğeyi Özelleştir',
    per_item_upgrades: 'Öğe Başına Yükseltmeler',
    number_of_items: 'Öğe Sayısı',
    upgrades_total: 'Toplam Yükseltmeler',
    total_price_message: '* Sepetinizdeki toplam fiyat hesaplanacaktır.',
    customizer_summary: 'Yapılandırma Özeti',
    save: 'Kaydet',
    field_required: '%{field} gereklidir',
    review_configuration: 'Yapılandırmayı Gözden Geçir',
    review_configurations: 'Yapılandırmaları Gözden Geçir',
    dismiss: 'Vazgeç',
    review: 'Gözden Geçir',
    cyrp_changed: 'Yapılandırılmış ürünlerindeki bileşenlerin kullanılabilirliği değişmiş olabilir. Ürün yapılandırmalarını incele.',
    cyrp_review_warning: 'Yapılandırılmış ürünlerindeki bileşenlerin kullanılabilirliği değişmiş olabilir. Devam etmek için ürün yapılandırmalarını incele.',
    quantity_not_available: 'Bu bileşen şu anda istenen miktarda mevcut değil. Lütfen stokta olan alternatif bir ürün seçin.',
    customized: 'Özelleştirilmiş',
    personalized: 'Kişiselleştirilmiş',
    customization: 'Özelleştirme',
    preview: 'Önizleme',
    preview_design: 'Önizleme Tasarımı',
    copy_preview_url: "Önizleme URL'sini kopyala",
    edit: 'Düzenle',
  }),
);
