define(
  ({
    copy: 'Skopírovať',
    paste: 'Prilepiť',
    copyToAll: 'Skopírovať do všetkých',
    add_to_cart: 'Pridať do košíka',
    addToCart: 'Pridať do košíka',
    remove: 'Odstrániť',
    added: 'Pridané',
    dropped: 'Odstránené',
    units: 'Jednotky',
    total: 'Spolu',
    subtotal: 'Medzisúčet',
    moveToShipment: 'Presunúť do zásielky',
    chooseOption: 'Vyberte možnosť',
    availability_msg: 'Niektoré vyžadované množstvá nie sú vo vašom termíne odoslania dostupné',
    atsWarning: 'Toto množstvo nebude %{availableOn} dostupné.',
    inventory_warehouse: 'Sklad inventára',
    notInCatalog: 'Do košíka nemožno pridať položku',
    notInCatalogExplanation: 'Ľutujeme, ale pridávanie položiek naprieč katalógmi je nedostupné.',
    digital_market_notInCatalogExplanation: 'Váš košík môže súčasne obsahovať položky iba od jednej značky',
    removeFromCartSuggestion: 'Chcete <link>svoj košík vyprázdniť?</link>',
    digital_market_removeFromCartSuggestion: 'Ak chcete pridať túto položku, môžete <link>si vyprázdniť košík</link>',
    emptyCart: 'Vyprázdniť košík',
    empty: 'Prázdne',
    emptyConfirmation: 'Naozaj chcete košík vyprázdniť?',
    available_on: 'Dostupné',
    personalization: 'Prispôsobenie',
    personalize: 'Prispôsobiť',
    configuration: 'Konfigurácia',
    configure: 'Prispôsobiť',
    placement: 'Umiestnenie',
    content: 'Obsah',
    color: 'Farba',
    product_specification: 'Špecifikácia produktu',
    type: 'Typ',
    logo: 'Logo',
    text: 'Text',
    text_and_logo: 'Text, logo,',
    other: 'Ostatné',
    value_might_be_stale: 'Z dôvodu zmien vášho košíka môže byť táto hodnota neaktuálna. Ak to chcete prepočítať, uložte svoju objednávku.',
    more_details: 'Viac podrobností',
    availability: 'Dostupnosť',
    no_scheduled_availability: 'Žiadna plánovaná dostupnosť',
    not_available_until: 'Nedostupné do <date />',
    available_as_of: 'Dostupné k <date />',
    units_bundled_singular_label: '%{units} zoskupená jednotka',
    units_bundled_label: 'Zoskupené jednotky: %{units}',
    add_bundle_to_cart: 'Pridať skupinu do košíka',
    bundles: 'Skupiny',
    bundled: 'Zoskupené',
    product: 'Produkt',
    sizes_and_quantities: 'Veľkosť/množstvo',
    units_singular_label: '%{units} jednotka',
    units_label: 'Jednotky: %{units}',
    current_availability: 'Aktuálna dostupnosť',
    future_availability: 'Budúca dostupnosť',
    size: 'Veľkosť',
    sku: 'SKU',
    availability_date: 'Dátum dostupnosti',
    quantity: 'Množstvo',
    availability_subject_to_change: 'Dostupnosť sa môže zmeniť',
    future_availability_subject_to_change: '*Dostupnosť sa môže zmeniť a zahŕňať zmluvný inventár.',
    contract_inventory_availability: 'Dostupnosť zmluvného inventára**',
    contract_inventory_footer: '**Dostupnosť zmluvného inventára sa aktualizuje po odoslaní objednávky.',
    yes: 'Áno',
    no: 'nie',
    copy_to_all_confirmation_message: 'Naozaj chcete skopírovať tento rozsah veľkosti na všetky štýly nižšie?',
    no_configurator_data: 'Nie sú dostupné žiadne údaje konfigurátora',
    oops: 'Ups!',
    something_went_wrong: 'Vyskytol sa nejaký problém!',
    error_message: 'Nerobte si starosti. Kontaktovali sme podporu, aby sme ich o tejto chybe informovali!',
    customize_item: 'Prispôsobiť položku',
    per_item_upgrades: 'Na vylepšenia položky',
    number_of_items: 'Počet položiek',
    upgrades_total: 'Súčet vylepšení',
    total_price_message: '* Celková cena sa vypočíta vo vašom košíku.',
    customizer_summary: 'Súhrn konfigurácie',
    save: 'Uložiť',
    field_required: '%{field} je povinný údaj',
    review_configuration: 'Skontrolovať konfiguráciu',
    review_configurations: 'Skontrolovať konfigurácie',
    dismiss: 'Zavrieť',
    review: 'Kontrola',
    cyrp_changed: 'Dostupnosť komponentov vo vašich nakonfigurovaných produktoch sa mohla zmeniť. Skontrolujte svoje konfigurácie produktov.',
    cyrp_review_warning: 'Dostupnosť komponentov vo vašich nakonfigurovaných produktoch sa mohla zmeniť. Ak chcete pokračovať, skontrolujte svoje konfigurácie produktov.',
    quantity_not_available: 'Komponent momentálne nie je dostupný v požadovanom množstve. Vyberte, prosím, alternatívnu položku, ktorá je na sklade.',
    customized: 'Prispôsobené',
    personalized: 'Personalizované',
    customization: 'Prispôsobenie',
    preview: 'Ukážka',
    preview_design: 'Zobraziť ukážku dizajnu',
    copy_preview_url: 'Skopírovať webovú adresu ukážky',
    edit: 'Upraviť',
  }),
);
