define(
  ({
    copy: 'Kopiera',
    paste: 'Klistra in',
    copyToAll: 'Kopiera till alla',
    add_to_cart: 'Lägg till i kundvagn',
    addToCart: 'Lägg till i kundvagn',
    remove: 'Ta bort',
    added: 'Tillagt',
    dropped: 'Tillagd',
    units: 'Enheter',
    total: 'Totalt',
    subtotal: 'Delsumma',
    moveToShipment: 'Flytta till leverans',
    chooseOption: 'Välj ett alternativ',
    availability_msg: 'Vissa av de beställda kvantiteterna är inte tillgängliga på leveransdatumet',
    atsWarning: 'Detta antal finns inte förrän %{availableOn}.',
    inventory_warehouse: 'Lager',
    notInCatalog: 'Kunde inte lägga till artikel i kundvagnen',
    notInCatalogExplanation: 'Tyvärr går det inte att lägga till artiklar mellan kundvagnar.',
    digital_market_notInCatalogExplanation: 'Din kundvagn kan endast innehålla produkter från ett märke åt gången',
    removeFromCartSuggestion: 'Vill du <link>tömma din kundvagn?</link>',
    digital_market_removeFromCartSuggestion: 'Du kan <link>tömma din kundvagn</link> för att lägga till denna produkt',
    emptyCart: 'Tom vagn',
    empty: 'Tom',
    emptyConfirmation: 'Är det säkert att du vill tömma din kundvagn?',
    available_on: 'Finns fr o m',
    personalization: 'Personlig anpassning',
    personalize: 'Anpassa personligt',
    configuration: 'Konfiguration',
    configure: 'Anpassa',
    placement: 'Placering',
    content: 'Innehåll',
    color: 'Färg',
    product_specification: 'Produktspecifikation',
    type: 'Typ',
    logo: 'Logotyp',
    text: 'Text',
    text_and_logo: 'Text, logotyp',
    other: 'Annan',
    value_might_be_stale: 'På grund av ändringar i din kundvagn är det möjligt att det här värdet är inaktuellt. Spara din beställning för att räkna om igen.',
    more_details: 'Fler detaljuppgifter',
    availability: 'Tillgänglighet',
    no_scheduled_availability: 'Ingen schemalagd tillgänglighet',
    not_available_until: 'Ej tillgänglig förrän <date />',
    available_as_of: 'I lager fr o m <date />',
    units_bundled_singular_label: '% {units} paketenhet',
    units_bundled_label: '% {units} paketenheter',
    add_bundle_to_cart: 'Lägg till paket i kundvagn',
    bundles: 'Paket',
    bundled: 'Paket',
    product: 'Produkt',
    sizes_and_quantities: 'Storlek / antal',
    units_singular_label: '% {units} enhet',
    units_label: '% {units} enheter',
    current_availability: 'Aktuell lagerstatus',
    future_availability: 'Framtida lagerstatus',
    size: 'Storlek',
    sku: 'SKU',
    availability_date: 'Lagerdatum',
    quantity: 'Antal',
    availability_subject_to_change: 'Lagerstatus kan komma att ändras',
    future_availability_subject_to_change: '*Tillgänglighet kan komma att ändras och inkluderar kontrakterade artiklar.',
    contract_inventory_availability: 'Tillgänglighet för kontrakterade artiklar**',
    contract_inventory_footer: '**Tillgängligheten för kontrakterade artiklar kommer att uppdateras när beställningen skickas in.',
    yes: 'Ja',
    no: 'Nej',
    copy_to_all_confirmation_message: 'Är det säkert att du vill kopiera dessa storlekar till alla stilar nedan?',
    no_configurator_data: 'Ingen konfigureringsdata tillgänglig',
    oops: 'Hoppsan!',
    something_went_wrong: 'Någonting gick fel!',
    error_message: 'Oroa dig inte, vi har kontaktat supporten och beskrivit felet för dem!',
    customize_item: 'Anpassa artikel',
    per_item_upgrades: 'Uppgraderingar per artikel',
    number_of_items: 'Antal artiklar',
    upgrades_total: 'Totalt antal uppgraderingar',
    total_price_message: '*Totalpriset kommer att beräknas i kundvagnen.',
    customizer_summary: 'Konfigureringsöversikt',
    save: 'Spara',
    field_required: '%{field} måste anges',
    review_configuration: 'Granska konfiguration',
    review_configurations: 'Granska konfigurationer',
    dismiss: 'Avfärda',
    review: 'Granska',
    cyrp_changed: 'Tillgängligheten för vissa komponenter i den/de konfigurerade produkterna kan ha ändrats. Granska din(a) produktkonfigurationer.',
    cyrp_review_warning: 'Tillgängligheten för vissa komponenter i den/de konfigurerade produkterna kan ha ändrats. Granska din(a) produktkonfigurationer.',
    quantity_not_available: 'Den här komponenten är inte tillgänglig i den begärda kvantiteten just nu. Välj en annan artikel som finns i lager.',
    customized: 'Anpassat',
    personalized: 'Personanpassat',
    customization: 'Anpassning',
    preview: 'Förhandsvisning',
    preview_design: 'Förhandsvisa design',
    copy_preview_url: 'Kopiera webbadress för förhandsvisning',
    edit: 'Redigera',
  }),
);
