define(
  ({
    copy: '複製',
    paste: '貼上',
    copyToAll: '複製至所有',
    add_to_cart: '加至購物車',
    addToCart: '加至購物車',
    remove: '移除',
    added: '已加入',
    dropped: '已捨棄',
    units: '單位',
    total: '總計',
    subtotal: '小計',
    moveToShipment: '移至貨運',
    chooseOption: '選擇你要的項目',
    availability_msg: '無法在你要求的貨運日期提供部分的貨件數量',
    atsWarning: '在%{availableOn}前不會有此數量。',
    inventory_warehouse: '庫存倉',
    notInCatalog: '未能將項目加至購物車',
    notInCatalogExplanation: '對不起，尚未可將產品在目錄之間加入。',
    digital_market_notInCatalogExplanation: '購物車一次只能放進單一品牌的商品',
    removeFromCartSuggestion: '你想<link>清空購物車</link>嗎？',
    digital_market_removeFromCartSuggestion: '你想<link>清空購物車</link>以加入此商品嗎？',
    emptyCart: '清空購物車',
    empty: '清空',
    emptyConfirmation: '你確定希望清空購物車嗎？',
    available_on: '將於以下日期到貨',
    personalization: '個人化',
    personalize: '個人化',
    configuration: '設定',
    configure: '自訂',
    placement: '配置',
    content: '內容',
    color: '色彩',
    product_specification: '商品規格',
    type: '種類',
    logo: '標記',
    text: '文字',
    text_and_logo: '文字標記',
    other: '其他',
    value_might_be_stale: '由於購物車變動，此數值或已過期。儲存訂單以重新計算。',
    more_details: '更多細節',
    availability: '存貨量',
    no_scheduled_availability: '無到貨排期',
    not_available_until: '<date />前無存貨',
    available_as_of: '<date />起有存貨',
    units_bundled_singular_label: '%{units} 同捆單位',
    units_bundled_label: '%{units} 同捆單位',
    add_bundle_to_cart: '將同捆包加至購物車',
    bundles: '同捆包',
    bundled: '同捆',
    product: '產品',
    sizes_and_quantities: '尺寸／數量',
    units_singular_label: '%{units} 單位',
    units_label: '%{units} 單位',
    current_availability: '目前存貨量',
    future_availability: '未來存貨量',
    size: '尺寸',
    sku: 'SKU',
    availability_date: '存貨量日期',
    quantity: '數量',
    availability_subject_to_change: '存貨量會因應實際情況而調整',
    future_availability_subject_to_change: '*存貨量會因應實際情況而調整，且包含合約存貨。',
    contract_inventory_availability: '合約庫存量**',
    contract_inventory_footer: '**合約庫存量將在訂單提交後更新。',
    yes: '是',
    no: '否',
    copy_to_all_confirmation_message: '確定要將此尺寸範圍複製到以下所有款式嗎？',
    no_configurator_data: '沒有設定資料可以顯示',
    oops: '不好意思！',
    something_went_wrong: '遇到問題！',
    error_message: '放心，我們已經為你聯絡支援團隊並報告有關問題！',
    customize_item: '自訂物品',
    per_item_upgrades: '每件物品升級',
    number_of_items: '物品數',
    upgrades_total: '升級總計',
    total_price_message: '* 購物車將會計算並顯示總價格。',
    customizer_summary: '設定摘要',
    save: '儲存',
    field_required: '必須填寫%{field}',
    review_configuration: '檢閱設定',
    review_configurations: '檢閱設定',
    dismiss: '取消',
    review: '檢閱',
    cyrp_changed: '你所設定的商品組合存貨可能有所變動。請檢查你的商品設定。',
    cyrp_review_warning: '你所設定的商品組合存貨可能有所變動。請檢查你的商品設定以繼續。',
    quantity_not_available: '此組合存貨目前無法滿足數量要求。請選擇目前有庫存的替代物品。',
    customized: '自訂',
    personalized: '個人化',
    customization: '自訂設定',
    preview: '預覽',
    preview_design: '預覽設計',
    copy_preview_url: '複製預覽連結',
    edit: '編輯',
  }),
);
